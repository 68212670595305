import { useQueryString } from '@hooks/client';
import { Game } from '@utils/AppConfig';

import { FilterItemVariants } from '@/shared/ui/header-filter-tab/header-filter-tab';
import { AllIcons } from '@/shared/ui/illustration/illustration';

export type FilterTab = {
  name: string;
  icon?: keyof AllIcons;
  variant: FilterItemVariants;
  href: string;
};

type FilterName = 'hot-sale' | 'properties' | 'categories';

export const useGetFilterTabs = () => {
  const { createQueryString } = useQueryString();

  const HotSale: FilterTab[] = [
    {
      name: 'Hot Sale',
      icon: 'fire-square-red',
      variant: 'secondary',
      href: createQueryString({
        pathname: '/shop',
        name: 'sale',
        value: 'hot-sale',
        asMultipleParams: true,
      }),
    },
    {
      name: 'New',
      icon: 'lightning',
      variant: 'tertiary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'new',
        asMultipleParams: true,
      }),
    },
  ];

  const AdoptProperties: FilterTab[] = [
    {
      name: 'Fly',
      icon: 'tag-category-fly',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'property',
        value: 'fly',
        enableToggle: false,
      }),
    },
    {
      name: 'Ride',
      icon: 'tag-category-ride',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'property',
        value: 'ride',
        enableToggle: false,
      }),
    },
    {
      name: 'Neon',
      icon: 'tag-category-neon',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'property',
        value: 'neon',
        enableToggle: false,
      }),
    },
    {
      name: 'Mega-Neon',
      icon: 'tag-category-mega_neon',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'property',
        value: 'mega-neon',
        enableToggle: false,
      }),
    },
  ];

  const AdoptCategories: FilterTab[] = [
    {
      name: 'Pets',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'pets',
        asMultipleParams: false,
      }),
    },
    {
      name: 'Eggs',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'eggs',
        asMultipleParams: false,
      }),
    },
    {
      name: 'Potions',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'potions',
        asMultipleParams: false,
      }),
    },
    {
      name: 'Transport',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'transport',
        asMultipleParams: false,
      }),
    },
  ];

  const mm2Categories: FilterTab[] = [
    {
      name: 'Knives',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'mm2-knives',
        asMultipleParams: false,
      }),
    },
    {
      name: 'Guns',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'mm2-guns',
        asMultipleParams: false,
      }),
    },
    {
      name: 'Pets',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'mm2-pets',
        asMultipleParams: false,
      }),
    },
    {
      name: 'Bundles',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'mm2-bundles',
        asMultipleParams: false,
      }),
    },
    {
      name: 'Chroma',
      variant: 'primary',
      href: createQueryString({
        pathname: '/shop',
        name: 'category',
        value: 'mm2-chroma-knives',
        asMultipleParams: false,
      }),
    },
  ];

  const tabs: Record<Game, Record<FilterName, any>> = {
    adopt: {
      'hot-sale': HotSale,
      properties: AdoptProperties,
      categories: AdoptCategories,
    },
    mm2: {
      'hot-sale': HotSale,
      properties: [],
      categories: mm2Categories,
    },
  };

  return (filterName: FilterName, game: Game): FilterTab[] => {
    return tabs[game][filterName];
  };
};
